import React, { useState, useEffect, useRef } from 'react';
import "./Textarea.css"
import {updateTextareas} from "../../libs/tools";
import cn from "classnames";
import {useSetting} from "../../contexts/setting";

const Textarea = ({
  handleTextChange,
  text,
}) => {

  const { fontOption } = useSetting();
  const onTextChange = (e) => {
    updateTextareas(e.target, null, e.target)
    handleTextChange(e.target.value);
  };


  const textareaClass = cn("textarea", `font-${fontOption}`);

  return (
    <div className="textarea-wrap">
      <label className="input-sizer">
        <textarea
          className={textareaClass}
          onChange={onTextChange}
          rows="1"
          value={text}
        />
      </label>
    </div>
  );
};

export default Textarea;

