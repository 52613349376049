import React, {useEffect} from "react";
import {useEdits} from "../../contexts/edits";
import {useSetting} from "../../contexts/setting";
import cn from "classnames";
import './index.css';
import {UI} from "../../data/constants";
import {updateTextareas} from "../../libs/tools";



const Button = ({value, handleClick, src, isCurrent}) => {
  const btnClass = cn('layout-button', { "selected": isCurrent });
  return (
    <button
      value={value}
      className={btnClass}
      onClick={() => handleClick(value)}
      style={{backgroundImage: `url(${src})`}}
    ></button>
  )
}


const LayoutSelector = ({ amount }) => {
  const {layoutOption, editLayoutOption } = useSetting();
  const {titles } = useEdits();
  const getSrc = value =>  (`${UI}/Layouts/${value}.svg`);


  const onBgButtonClick = value => {
    editLayoutOption(value)
    updateTextareas(titles.main, 'title')
  }


  return (
    <div className="layout-selector">
      {[...new Array(amount)].map((x, i) => (
        <Button
          key={i+1}
          value={i+1}
          src={getSrc(i+1)}
          handleClick={onBgButtonClick}
          isCurrent={+layoutOption === i+1}
        />
      ))}
    </div>
  )}

export default LayoutSelector;