import React, { useContext, useState, createContext, useCallback } from "react";

import {TAGS} from "../data/constants";

export const Setting = createContext(
  {
    setting: {},
    editSetting: () => {},
  });




export const SettingContextProvider = ({ children }) => {

  const [ channel, setChannel ] = useState({ label: "Social Media & Blog", value: "Social" });
  const [ product, setProduct ] = useState({label: "IntelliJ IDEA", value: "IJ"});
  const [ type, setType ] = useState({label: "General", value: "general"});
  const [ bgOption, setBgOption ] = useState("1");
  const [ layoutOption, setLayoutOption ] = useState("1");
  const [ iconOption, setIconOption ] = useState("key");
  const [ fontOption, setFontOption ] = useState("en");
  const [ palettes, setPalettes ] = useState({});
  const [ fontVariation, setFontVariations ] = useState({});
  const [ tag, setTag ] = useState(TAGS[2]);


  const editFontOption = value => {
    setFontOption(value);
  };
  const editBgOption = value => {
    setBgOption(value);
  };

const editTag = value => {
  setTag(value);
}
  const editIconOption = value => {
    setIconOption(value);
  };

  const editLayoutOption = value => {
    setLayoutOption(value);
  };
  const editType = value => {
    setType(value);
  };
  const editChannel = value => {
    setChannel(value);
  };

  const editProduct = value => {
    setProduct(value);
  }

  const editPalettes = value => {
    setPalettes(value);
  };

  const editFontVariations = value => {
    setFontVariations(value);
  }

  return (
    <Setting.Provider value={{
      channel,
      product,
      type,
      palettes,
      fontVariation,
      bgOption,
      layoutOption,
      iconOption,
      tag,
      fontOption,
      editFontOption,
      editChannel,
      editProduct,
      editType,
      editPalettes,
      editFontVariations,
      editBgOption,
      editLayoutOption,
      editIconOption,
      editTag,
    }}>
      { children }
    </Setting.Provider>
  );
}



export function useSetting() {
  const context = useContext(Setting);
  if (context === undefined) {
    throw new Error("Setting.Context must be used within the Images.Provider");
  }
  return context;
}