import {useEffect, useState, useContext} from "react";
import Twitter from "./Twitter/index.js";
import Facebook from "./Facebook/index.js";
import LinkedIn from "./LinkedIn/index.js";
import InstagramStories from "./InstagramStories/index.js";
import InstagramPost from "./InstagramPost/index.js";
import {useEdits} from '../../contexts/edits.js';
import {useImages} from '../../contexts/images.js';
import {checkIfIDE, checkImageLoaded, isEmptyObj, updateTextareas} from "../../libs/tools";
import {useSetting} from "../../contexts/setting";
import {BACKGROUNDS, GENERAL_BG,TAGS, PLACEHOLDERS} from "../../data/constants";
import {Products} from "../../data/data";

const Social = () => {
  const [bgUrl, setBgUrl] = useState("");
  const { product, type, channel, bgOption, editTag, fontOption } = useSetting();
  const { editTitles, titles, subtitles,  editSubtitles} = useEdits();
  const { customBg } = useImages();
  const [defaultTitles, setDefaultTitles] = useState(PLACEHOLDERS[fontOption].title);
  const [defaultSubtitles, setDefaultSubtitles] = useState(PLACEHOLDERS[fontOption].subtitle);


  useEffect(() => {
    if (isEmptyObj(titles)) {
      editTitles(defaultTitles, "main", false)
    }
    if (isEmptyObj(subtitles)) {
      editSubtitles(defaultSubtitles, "main", false)
    }

  }, [])

  useEffect(() => {
    if (bgOption === "custom") {
      setBgUrl(customBg);
    } else {
      loadNormBg()
    }
  }, [product, type, channel, bgOption, customBg]);


  //TODO move the check to a higher order component
  const loadNormBg = () => {
    const src = `${BACKGROUNDS}/${channel.value}/${type.value}/${bgOption}/${product.value}.png`;

    const outdated = `${BACKGROUNDS}/${channel.value}/${type.value}/${bgOption}/${product.value}-outdated.png`;
    setBgUrl(src);

    checkImageLoaded(src)
      .then(result => {
        if (result) {
          setBgUrl(src);
          const isOutdated = Products.find(elem =>  elem.value === product.value).tag === "outdated";
          if (isOutdated) {
            editTag(TAGS[1]);
          } else {
            editTag(TAGS[2]);
          }
        } else {
          checkImageLoaded(outdated)
            .then(oldResult => {
              if (oldResult) {
                setBgUrl(outdated);
                editTag(TAGS[1]);
              } else {
                setBgUrl(`${GENERAL_BG}/${product.value}.png`);
                editTag(TAGS[0]);
              }
            });
        }
      })
  }


  return (
    <>
      <Twitter
        isLocked={false}
        id="Twitter"
        bgUrl={bgUrl}
      />

      <Facebook
        isLocked={false}
        id="Facebook"
        bgUrl={bgUrl}
      />

      <LinkedIn
        isLocked={false}
        id="LinkedIn"
        bgUrl={bgUrl}
      />

      <InstagramStories
        isLocked={false}
        id="InstagramStories"
        bgUrl={bgUrl}
      />

{/*      <InstagramPost
        isLocked={false}
        id="InstagramPost"
        bgUrl={bgUrl}
      />*/}





      {/*<EmailHeader
        productOption={product}
        productNames={texts.productNames || productNames}
        updateProductNames={value => texts.editProductNames(value)}
        titles={texts.titles || titles}
        updateTitles={value => texts.editTitles(value)}
        dates={texts.dates || dates}
        updateDates={value => texts.editDates(value)}
        isLocked={false}
        idOption={{label: "Email Header", value: "EmailHeader"}}
        isIDE={isIDE}
      />

      <Email
        productOption={product}
        productNames={texts.productNames || productNames}
        updateProductNames={value => texts.editProductNames(value)}
        titles={texts.titles || titles}
        updateTitles={value => texts.editTitles(value)}
        dates={texts.dates || dates}
        updateDates={value => texts.editDates(value)}
        isLocked={false}
        idOption={{label: "Email", value: "Email"}}
        isIDE={isIDE}
      />

      <BlogFeatured
        openai={openai}
        productOption={product}
        productNames={texts.productNames || productNames}
        updateProductNames={value => texts.editProductNames(value)}
        titles={texts.titles || titles}
        updateTitles={value => texts.editTitles(value)}
        dates={texts.dates || dates}
        updateDates={value => texts.editDates(value)}
        isLocked={false}
        idOption={{label: "Blog Featured", value: "BlogFeatured"}}
        isIDE={isIDE}
      />


      <BlogSocialShare
        productOption={product}
        productNames={texts.productNames || productNames}
        updateProductNames={value => texts.editProductNames(value)}
        titles={texts.titles || titles}
        updateTitles={value => texts.editTitles(value)}
        dates={texts.dates || dates}
        updateDates={value => texts.editDates(value)}
        isLocked={false}
        idOption={{label: "Blog Social Share", value: "BlogSocialShare"}}
        isIDE={isIDE}
      />*/}
  </>
  )
}

export default Social;