import './index.css';
import { Route, Routes, Navigate } from "react-router-dom";
import React, {useEffect} from "react";
import { Products } from '../../data/data.js';
import { useEdits } from '../../contexts/edits.js';
import {changeFontSize} from "../../libs/tools";
import TemplateRoute from '../TemplateRoute';
import {useSetting} from "../../contexts/setting";
import {KRAKEN_COLORS, TAGS } from "../../data/constants";
import cn from "classnames";

let styleEl;

const Index = ({ passTexts }) => {
  const edits = useEdits();
  const setting = useSetting();
  const { palettes, channel, product, tag, editTag, type, bgOption } = setting;
  // console.log('product.value',product, setting)

  const colorId = KRAKEN_COLORS[product.value];
  const palette = palettes[colorId];


  useEffect(() => {
    const textarea = document.querySelector('textarea');
    textarea?.focus();
    textarea?.setSelectionRange(textarea.value.length,textarea.value.length);
  }, []);

  // change font size when font variation switcher is triggered
  useEffect(() => {
    const isTypeVariation = (channel.value === "Ads") && (typeof edits.fontVariation === 'number');

    if (isTypeVariation) {
      const fv = edits.fontVariation;
      const container = document.querySelector(".templates");
      const textareas = container.querySelectorAll("textarea");
      const styledElements = [...textareas].map(x => x.parentNode);

      if (styleEl) document.head.removeChild(styleEl);
      styleEl = document.createElement("style");
      document.head.appendChild(styleEl);

      changeFontSize(styledElements, fv, styleEl);
    }
  }, [edits.fontVariation])


  const tagClass = cn("tag", `tag-${tag.value}`);


  return (
    <div className="templates">

      <div className="templates-inner">
        <label className={tagClass}>{tag.label}</label>


        <Routes>
          <Route path='/' element={<Navigate to="/Social/JB"/>}/>
          <Route path="/:template/:product?" element={
            <TemplateRoute palette={palette} passTexts={passTexts}/>
          }
          />
          <Route
            path="*"
            element={
              <main style={{padding: "1rem", color: "white"}}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default Index;