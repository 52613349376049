import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import {EditsContextProvider}   from './contexts/edits.js';
import {DataContextProvider}   from './contexts/data.js';
import {ImagesContextProvider}   from './contexts/images.js';
import {SettingContextProvider} from "./contexts/setting";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <EditsContextProvider>
        <ImagesContextProvider>
          <SettingContextProvider>
            <App />
          </SettingContextProvider>
        </ImagesContextProvider>
      </EditsContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

