// export const backgroundData = {
//   Social: {
//     "general": {
//       "1": {
//         x: {
//           Twitter: -100,
//           Facebook: -100,
//           InstagramStories: -288,
//           InstagramPost: -288,
//           LinkedIn: -100,
//           YouTubeCommunity: -100,
//           BlogFeatured: -100,
//           BlogSocialShare: -100,
//         },
//         y: {
//           Twitter: -1625,
//           Facebook: -1666,
//           InstagramStories: -1680,
//           InstagramPost: -1680,
//           LinkedIn: -1625,
//           YouTubeCommunity: -1625,
//           BlogFeatured: -1625,
//           BlogSocialShare: -1625,
//         },
//         scale: {
//           Twitter: 1,
//           Facebook: 1,
//           InstagramStories: 1.65,
//           InstagramPost: 1.65,
//           LinkedIn: 1,
//           YouTubeCommunity: 1,
//           BlogFeatured: 1,
//           BlogSocialShare: 1,
//         },
//       },
//       "2": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "3": {
//         x: { Twitter: -511, Facebook: -797, InstagramStories: -322 },
//         y: { Twitter: -328, Facebook: -707, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "4": {
//         x: { Twitter: -300, Facebook: -100, InstagramStories: -353 },
//         y: { Twitter: -276, Facebook: -1180, InstagramStories: -937 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "5": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "6": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "custom": {
//         x: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1 },
//       },
//     },
//     "news": {
//       "1": {
//         x: { Twitter: -100, Facebook: -100, InstagramStories: -288 },
//         y: { Twitter: -1625, Facebook: -1666, InstagramStories: -1680 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "2": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "3": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "4": {
//         x: { Twitter: -300, Facebook: -100, InstagramStories: -353 },
//         y: { Twitter: -276, Facebook: -1180, InstagramStories: -937 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "5": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "6": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "custom": {
//         x: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1 },
//       },
//     },
//     "eap": {
//       "1": {
//         x: { Twitter: -100, Facebook: -100, InstagramStories: -288 },
//         y: { Twitter: -1625, Facebook: -1666, InstagramStories: -1680 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "2": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "3": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "4": {
//         x: { Twitter: -300, Facebook: -100, InstagramStories: -353 },
//         y: { Twitter: -276, Facebook: -1180, InstagramStories: -937 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "5": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "6": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "custom": {
//         x: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1 },
//       },
//     },
//     "events": {
//       "1": {
//         x: { Twitter: -100, Facebook: -100, InstagramStories: -288 },
//         y: { Twitter: -1625, Facebook: -1666, InstagramStories: -1680 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "2": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "3": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "4": {
//         x: { Twitter: -300, Facebook: -100, InstagramStories: -353 },
//         y: { Twitter: -276, Facebook: -1180, InstagramStories: -937 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "5": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "6": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "custom": {
//         x: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1 },
//       },
//     },
//     "bug-fix": {
//       "1": {
//         x: { Twitter: -100, Facebook: -100, InstagramStories: -288 },
//         y: { Twitter: -1625, Facebook: -1666, InstagramStories: -1680 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "2": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "3": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "4": {
//         x: { Twitter: -300, Facebook: -100, InstagramStories: -353 },
//         y: { Twitter: -276, Facebook: -1180, InstagramStories: -937 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "5": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "6": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "custom": {
//         x: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1 },
//       },
//     },
//     "beta": {
//       "1": {
//         x: { Twitter: -100, Facebook: -100, InstagramStories: -288 },
//         y: { Twitter: -1625, Facebook: -1666, InstagramStories: -1680 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "2": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "3": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "4": {
//         x: { Twitter: -300, Facebook: -100, InstagramStories: -353 },
//         y: { Twitter: -276, Facebook: -1180, InstagramStories: -937 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "5": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "6": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "custom": {
//         x: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1 },
//       },
//     },
//     "best-practices": {
//       "1": {
//         x: { Twitter: -100, Facebook: -100, InstagramStories: -288 },
//         y: { Twitter: -1625, Facebook: -1666, InstagramStories: -1680 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "2": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "3": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "4": {
//         x: { Twitter: -300, Facebook: -100, InstagramStories: -353 },
//         y: { Twitter: -276, Facebook: -1180, InstagramStories: -937 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "5": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "6": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "custom": {
//         x: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1 },
//       },
//     },
//     "release": {
//       "1": {
//         x: { Twitter: -100, Facebook: -100, InstagramStories: -288 },
//         y: { Twitter: -1625, Facebook: -1666, InstagramStories: -1680 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "2": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "3": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "4": {
//         x: { Twitter: -300, Facebook: -100, InstagramStories: -353 },
//         y: { Twitter: -276, Facebook: -1180, InstagramStories: -937 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "5": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "6": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "custom": {
//         x: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1 },
//       },
//     },
//     "roadmap": {
//       "1": {
//         x: { Twitter: -100, Facebook: -100, InstagramStories: -288 },
//         y: { Twitter: -1625, Facebook: -1666, InstagramStories: -1680 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "2": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "3": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "4": {
//         x: { Twitter: -300, Facebook: -100, InstagramStories: -353 },
//         y: { Twitter: -276, Facebook: -1180, InstagramStories: -937 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "5": {
//         x: { Twitter: 0, Facebook: -240, InstagramStories: -430 },
//         y: { Twitter: -500, Facebook: -925, InstagramStories: -1050 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1.65 },
//       },
//       "6": {
//         x: { Twitter: -200, Facebook: -190, InstagramStories: -322 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: -1030 },
//         scale: { Twitter: 1.65, Facebook: 1.65, InstagramStories: 1.65 },
//       },
//       "custom": {
//         x: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         y: { Twitter: 0, Facebook: 0, InstagramStories: 0 },
//         scale: { Twitter: 1, Facebook: 1, InstagramStories: 1 },
//       },
//     },
//   },
// };




export const backgroundData = {
  Social: {
    "general": {
      "1": {
        x: {
          Twitter: -100,
          Facebook: -100,
          InstagramStories: -288,
          InstagramPost: -100,
          LinkedIn: -100,
          YouTubeCommunity: -100,
          BlogFeatured: -100,
          BlogSocialShare: -100
        },
        y: {
          Twitter: -1625,
          Facebook: -1666,
          InstagramStories: -1680,
          InstagramPost: -1625,
          LinkedIn: -1625,
          YouTubeCommunity: -1625,
          BlogFeatured: -1625,
          BlogSocialShare: -1625
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "2": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "3": {
        x: {
          Twitter: -511,
          Facebook: -797,
          InstagramStories: -322,
          InstagramPost: -511,
          LinkedIn: -511,
          YouTubeCommunity: -511,
          BlogFeatured: -511,
          BlogSocialShare: -511
        },
        y: {
          Twitter: -328,
          Facebook: -707,
          InstagramStories: -1030,
          InstagramPost: -328,
          LinkedIn: -328,
          YouTubeCommunity: -328,
          BlogFeatured: -328,
          BlogSocialShare: -328
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1.65,
          YouTubeCommunity: 1.65,
          BlogFeatured: 1.65,
          BlogSocialShare: 1.65
        }
      },
      "4": {
        x: {
          Twitter: -300,
          Facebook: -100,
          InstagramStories: -353,
          InstagramPost: -300,
          LinkedIn: -300,
          YouTubeCommunity: -300,
          BlogFeatured: -300,
          BlogSocialShare: -300
        },
        y: {
          Twitter: -276,
          Facebook: -1180,
          InstagramStories: -937,
          InstagramPost: -276,
          LinkedIn: -276,
          YouTubeCommunity: -276,
          BlogFeatured: -276,
          BlogSocialShare: -276
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "5": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "6": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1.65,
          YouTubeCommunity: 1.65,
          BlogFeatured: 1.65,
          BlogSocialShare: 1.65
        }
      },
      "custom": {
        x: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      }
    },
    "news": {
      "1": {
        x: {
          Twitter: -100,
          Facebook: -100,
          InstagramStories: -288,
          InstagramPost: -100,
          LinkedIn: -100,
          YouTubeCommunity: -100,
          BlogFeatured: -100,
          BlogSocialShare: -100
        },
        y: {
          Twitter: -1625,
          Facebook: -1666,
          InstagramStories: -1680,
          InstagramPost: -1625,
          LinkedIn: -1625,
          YouTubeCommunity: -1625,
          BlogFeatured: -1625,
          BlogSocialShare: -1625
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "2": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "3": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1.65,
          YouTubeCommunity: 1.65,
          BlogFeatured: 1.65,
          BlogSocialShare: 1.65
        }
      },
      "4": {
        x: {
          Twitter: -300,
          Facebook: -100,
          InstagramStories: -353,
          InstagramPost: -300,
          LinkedIn: -300,
          YouTubeCommunity: -300,
          BlogFeatured: -300,
          BlogSocialShare: -300
        },
        y: {
          Twitter: -276,
          Facebook: -1180,
          InstagramStories: -937,
          InstagramPost: -276,
          LinkedIn: -276,
          YouTubeCommunity: -276,
          BlogFeatured: -276,
          BlogSocialShare: -276
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "5": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "6": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1.65,
          YouTubeCommunity: 1.65,
          BlogFeatured: 1.65,
          BlogSocialShare: 1.65
        }
      },
      "custom": {
        x: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      }
    },
    "eap": {
      "1": {
        x: {
          Twitter: -100,
          Facebook: -100,
          InstagramStories: -288,
          InstagramPost: -100,
          LinkedIn: -100,
          YouTubeCommunity: -100,
          BlogFeatured: -100,
          BlogSocialShare: -100
        },
        y: {
          Twitter: -1625,
          Facebook: -1666,
          InstagramStories: -1680,
          InstagramPost: -1625,
          LinkedIn: -1625,
          YouTubeCommunity: -1625,
          BlogFeatured: -1625,
          BlogSocialShare: -1625
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "2": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "3": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1.65,
          YouTubeCommunity: 1.65,
          BlogFeatured: 1.65,
          BlogSocialShare: 1.65
        }
      },
      "4": {
        x: {
          Twitter: -300,
          Facebook: -100,
          InstagramStories: -353,
          InstagramPost: -300,
          LinkedIn: -300,
          YouTubeCommunity: -300,
          BlogFeatured: -300,
          BlogSocialShare: -300
        },
        y: {
          Twitter: -276,
          Facebook: -1180,
          InstagramStories: -937,
          InstagramPost: -276,
          LinkedIn: -276,
          YouTubeCommunity: -276,
          BlogFeatured: -276,
          BlogSocialShare: -276
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "5": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "6": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1.65,
          YouTubeCommunity: 1.65,
          BlogFeatured: 1.65,
          BlogSocialShare: 1.65
        }
      },
      "custom": {
        x: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      }
    },
    "events": {
      "1": {
        x: {
          Twitter: -100,
          Facebook: -100,
          InstagramStories: -288,
          InstagramPost: -100,
          LinkedIn: -100,
          YouTubeCommunity: -100,
          BlogFeatured: -100,
          BlogSocialShare: -100
        },
        y: {
          Twitter: -1625,
          Facebook: -1666,
          InstagramStories: -1680,
          InstagramPost: -1625,
          LinkedIn: -1625,
          YouTubeCommunity: -1625,
          BlogFeatured: -1625,
          BlogSocialShare: -1625
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "2": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "3": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1.65,
          YouTubeCommunity: 1.65,
          BlogFeatured: 1.65,
          BlogSocialShare: 1.65
        }
      },
      "4": {
        x: {
          Twitter: -300,
          Facebook: -100,
          InstagramStories: -353,
          InstagramPost: -300,
          LinkedIn: -300,
          YouTubeCommunity: -300,
          BlogFeatured: -300,
          BlogSocialShare: -300
        },
        y: {
          Twitter: -276,
          Facebook: -1180,
          InstagramStories: -937,
          InstagramPost: -276,
          LinkedIn: -276,
          YouTubeCommunity: -276,
          BlogFeatured: -276,
          BlogSocialShare: -276
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "5": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "6": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1.65,
          YouTubeCommunity: 1.65,
          BlogFeatured: 1.65,
          BlogSocialShare: 1.65
        }
      },
      "custom": {
        x: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      }
    },
    "bug-fix": {
      "1": {
        x: {
          Twitter: -100,
          Facebook: -100,
          InstagramStories: -288,
          InstagramPost: -100,
          LinkedIn: -100,
          YouTubeCommunity: -100,
          BlogFeatured: -100,
          BlogSocialShare: -100
        },
        y: {
          Twitter: -1625,
          Facebook: -1666,
          InstagramStories: -1680,
          InstagramPost: -1625,
          LinkedIn: -1625,
          YouTubeCommunity: -1625,
          BlogFeatured: -1625,
          BlogSocialShare: -1625
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "2": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "3": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1.65,
          YouTubeCommunity: 1.65,
          BlogFeatured: 1.65,
          BlogSocialShare: 1.65
        }
      },
      "4": {
        x: {
          Twitter: -300,
          Facebook: -100,
          InstagramStories: -353,
          InstagramPost: -300,
          LinkedIn: -300,
          YouTubeCommunity: -300,
          BlogFeatured: -300,
          BlogSocialShare: -300
        },
        y: {
          Twitter: -276,
          Facebook: -1180,
          InstagramStories: -937,
          InstagramPost: -276,
          LinkedIn: -276,
          YouTubeCommunity: -276,
          BlogFeatured: -276,
          BlogSocialShare: -276
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "5": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "6": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1.65,
          YouTubeCommunity: 1.65,
          BlogFeatured: 1.65,
          BlogSocialShare: 1.65
        }
      },
      "custom": {
        x: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      }
    },
    "beta": {
      "1": {
        x: {
          Twitter: -100,
          Facebook: -100,
          InstagramStories: -288,
          InstagramPost: -100,
          LinkedIn: -100,
          YouTubeCommunity: -100,
          BlogFeatured: -100,
          BlogSocialShare: -100
        },
        y: {
          Twitter: -1625,
          Facebook: -1666,
          InstagramStories: -1680,
          InstagramPost: -1625,
          LinkedIn: -1625,
          YouTubeCommunity: -1625,
          BlogFeatured: -1625,
          BlogSocialShare: -1625
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "2": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "3": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1.65,
          YouTubeCommunity: 1.65,
          BlogFeatured: 1.65,
          BlogSocialShare: 1.65
        }
      },
      "4": {
        x: {
          Twitter: -300,
          Facebook: -100,
          InstagramStories: -353,
          InstagramPost: -300,
          LinkedIn: -300,
          YouTubeCommunity: -300,
          BlogFeatured: -300,
          BlogSocialShare: -300
        },
        y: {
          Twitter: -276,
          Facebook: -1180,
          InstagramStories: -937,
          InstagramPost: -276,
          LinkedIn: -276,
          YouTubeCommunity: -276,
          BlogFeatured: -276,
          BlogSocialShare: -276
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "5": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "6": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1.65,
          YouTubeCommunity: 1.65,
          BlogFeatured: 1.65,
          BlogSocialShare: 1.65
        }
      },
      "custom": {
        x: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      }
    },
    "best-practices": {
      "1": {
        x: {
          Twitter: -100,
          Facebook: -100,
          InstagramStories: -288,
          InstagramPost: -100,
          LinkedIn: -100,
          YouTubeCommunity: -100,
          BlogFeatured: -100,
          BlogSocialShare: -100
        },
        y: {
          Twitter: -1625,
          Facebook: -1666,
          InstagramStories: -1680,
          InstagramPost: -1625,
          LinkedIn: -1625,
          YouTubeCommunity: -1625,
          BlogFeatured: -1625,
          BlogSocialShare: -1625
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "2": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "3": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1.65,
          YouTubeCommunity: 1.65,
          BlogFeatured: 1.65,
          BlogSocialShare: 1.65
        }
      },
      "4": {
        x: {
          Twitter: -300,
          Facebook: -100,
          InstagramStories: -353,
          InstagramPost: -300,
          LinkedIn: -300,
          YouTubeCommunity: -300,
          BlogFeatured: -300,
          BlogSocialShare: -300
        },
        y: {
          Twitter: -276,
          Facebook: -1180,
          InstagramStories: -937,
          InstagramPost: -276,
          LinkedIn: -276,
          YouTubeCommunity: -276,
          BlogFeatured: -276,
          BlogSocialShare: -276
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "5": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "6": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1.65,
          YouTubeCommunity: 1.65,
          BlogFeatured: 1.65,
          BlogSocialShare: 1.65
        }
      },
      "custom": {
        x: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },

    },
    release: {
      "1": {
        x: {
          Twitter: -100,
          Facebook: -100,
          InstagramStories: -288,
          InstagramPost: -100,
          LinkedIn: -100,
          YouTubeCommunity: -100,
          BlogFeatured: -100,
          BlogSocialShare: -100
        },
        y: {
          Twitter: -1625,
          Facebook: -1666,
          InstagramStories: -1680,
          InstagramPost: -1625,
          LinkedIn: -1625,
          YouTubeCommunity: -1625,
          BlogFeatured: -1625,
          BlogSocialShare: -1625
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        }
      },
      "2": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        },
      },
      "3": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        },
      },
      "4": {
        x: {
          Twitter: -300,
          Facebook: -100,
          InstagramStories: -353,
          InstagramPost: -300,
          LinkedIn: -300,
          YouTubeCommunity: -300,
          BlogFeatured: -300,
          BlogSocialShare: -300
        },
        y: {
          Twitter: -276,
          Facebook: -1180,
          InstagramStories: -937,
          InstagramPost: -276,
          LinkedIn: -276,
          YouTubeCommunity: -276,
          BlogFeatured: -276,
          BlogSocialShare: -276
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        },
      },
      "5": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        },
      },
      "6": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        },
      },
      custom: {
        x: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1,
          InstagramPost: 1,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        },
      },
    },
    roadmap: {
      "1": {
        x: {
          Twitter: -100,
          Facebook: -100,
          InstagramStories: -288,
          InstagramPost: -100,
          LinkedIn: -100,
          YouTubeCommunity: -100,
          BlogFeatured: -100,
          BlogSocialShare: -100
        },
        y: {
          Twitter: -1625,
          Facebook: -1666,
          InstagramStories: -1680,
          InstagramPost: -1625,
          LinkedIn: -1625,
          YouTubeCommunity: -1625,
          BlogFeatured: -1625,
          BlogSocialShare: -1625
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        },
      },
      "2": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        },
      },
      "3": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        },
      },
      "4": {
        x: {
          Twitter: -300,
          Facebook: -100,
          InstagramStories: -353,
          InstagramPost: -300,
          LinkedIn: -300,
          YouTubeCommunity: -300,
          BlogFeatured: -300,
          BlogSocialShare: -300
        },
        y: {
          Twitter: -276,
          Facebook: -1180,
          InstagramStories: -937,
          InstagramPost: -276,
          LinkedIn: -276,
          YouTubeCommunity: -276,
          BlogFeatured: -276,
          BlogSocialShare: -276
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        },
      },
      "5": {
        x: {
          Twitter: 0,
          Facebook: -240,
          InstagramStories: -430,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: -500,
          Facebook: -925,
          InstagramStories: -1050,
          InstagramPost: -500,
          LinkedIn: -500,
          YouTubeCommunity: -500,
          BlogFeatured: -500,
          BlogSocialShare: -500
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        },
      },
      "6": {
        x: {
          Twitter: -200,
          Facebook: -190,
          InstagramStories: -322,
          InstagramPost: -200,
          LinkedIn: -200,
          YouTubeCommunity: -200,
          BlogFeatured: -200,
          BlogSocialShare: -200
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: -1030,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1.65,
          Facebook: 1.65,
          InstagramStories: 1.65,
          InstagramPost: 1.65,
          LinkedIn: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        },
      },
      custom: {
        x: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        y: {
          Twitter: 0,
          Facebook: 0,
          InstagramStories: 0,
          InstagramPost: 0,
          LinkedIn: 0,
          YouTubeCommunity: 0,
          BlogFeatured: 0,
          BlogSocialShare: 0
        },
        scale: {
          Twitter: 1,
          Facebook: 1,
          LinkedIn: 1,
          InstagramStories: 1,
          YouTubeCommunity: 1,
          BlogFeatured: 1,
          BlogSocialShare: 1
        },
      },
    }
  }
};

