
export function applyStyle(
  node,
  options,
) {
  const { style } = node

  if (options?.backgroundColor) {
    style.backgroundColor = options.backgroundColor
  }

  if (options?.width) {
    style.width = `${options.width}px`
  }

  if (options?.height) {
    style.height = `${options.height}px`
  }

  const manual = options?.style
  if (manual != null) {
    Object.keys(manual).forEach((key) => {
      style[key] = manual[key]
    })
  }

  return node
}