import React, { useContext, useState, createContext, useCallback } from "react";
import {updateTextareas} from "../libs/tools";
import {PLACEHOLDERS} from "../data/constants";


export const Edits = createContext(
  {
    names: {},
    titles: {},
    positions: {},
    dates: {},
    fontVariation: {},
    productNames: {},
    editProductNames: {},
    images: {},
    text1: {},
    text2: {},
    bgTransform: {},
    editNames: () => {},
    editTitles: () => {},
    editDates: () => {},
    editPositions: () => {},
    reset: () => {},
    editFontVariation: () => {},
    editImages: () => {},
    editText1: () => {},
    editText2: () => {},
    editBgTransform: () => {},
  });




export const EditsContextProvider = ({ children }) => {

  const [ names, setNames ] = useState({});
  const [ titles, setTitles ] = useState({});
  const [ subtitles, setSubTitles ] = useState({});
  const [ positions, setPositions ] = useState({});
  const [ dates, setDates ] = useState({});
  const [ productNames, setProductNames ] = useState({});
  const [ fontVariation, setFontVariation ] = useState(1);
  const [ images, setImages ] = useState({});
  const [ text1, setText1 ] = useState({});
  const [ text2, setText2 ] = useState({});


  const reset = () => {
    setNames({});
    setTitles({});
    setDates({});
    setPositions({});
    setProductNames({});
    setFontVariation(1);
    setImages({});
    setText1({});
    setText2({});
    setSubTitles({});
  }



  const editSubtitles = ( value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    setSubTitles((curTitles) => {
      const nextTitles = {...curTitles};
      if (!nextTitles[currentId]) { nextTitles[currentId] = {}; }
      // nextTitles[id] = {...nextTitles[id]};
      nextTitles[currentId] = value;
      // TODO fix when implementing locks
      updateTextareas(value, "subtitle");
      return nextTitles;
    });
  }
  const editNames = (value) => {
    setNames(value);
  }
  const editImages = (value) => {
    setImages(value);
  }

  const editTitles = ( value, id, isLocked = false) => {
    const currentId = isLocked ? id : "main";

    setTitles((curTitles) => {
      const nextTitles = {...curTitles};
      if (!nextTitles[currentId]) { nextTitles[currentId] = {}; }
      // nextTitles[id] = {...nextTitles[id]};
      nextTitles[currentId] = value;
      updateTextareas(value, "title");
      return nextTitles;
    });
  }

  const loadTitles = value => {
    setTitles(value);
  }

  const editDates = (value) => {
    setDates(value);
  }

  const editPositions = (value) => {
    setPositions(value);
  }

  const editProductNames = value => {
    setProductNames(value)
  }

  const editFontVariation = value => {
    setFontVariation(value);
  }

  const editText1 = value => {
    setText1(value);
  }
  const editText2 = value => {
    setText1(value);
  }

  return (
    <Edits.Provider value={{
      names,
      titles,
      positions,
      dates,
      productNames,
      fontVariation,
      images,
      text1,
      text2,
      subtitles,
      loadTitles,
      editProductNames,
      editNames,
      editTitles,
      editDates,
      editPositions,
      editFontVariation,
      editImages,
      reset,
      editText1,
      editText2,
      editSubtitles,
    }}>
      { children }
    </Edits.Provider>
  );
}



export function useEdits() {
  const context = useContext(Edits);
  if (context === undefined) {
    throw new Error("Edits.Context must be used within the Edits.Provider");
  }
  return context;
}