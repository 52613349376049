import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import { Select } from '@rescui/select';
import Button from '@rescui/button';
import { Toggle } from '@rescui/toggle';
import { Products } from '../../data/data.js';
import { useNavigate } from "react-router-dom";
import {options, fontSizes} from "../../data/constants";
import {isEmptyObj} from "../../libs/tools";
import {useEdits} from '../../contexts/edits.js';
import {useSetting} from '../../contexts/setting.js';
import BgSelector from "../BgSelector";
import LayoutSelector from "../LayoutSelector";
import IconSelector from "../IconSelector";
import FontSelector from "../FontSelector";
import {API} from "../../api";
import cn from "classnames";

const Index = (props ) => {

  const { handleExport, handleSaveScene } = props;
  const navigate = useNavigate();
  const edits = useEdits();

  const { product, editBgOption, editProduct, channel, editChannel, type, editType, layoutOption } = useSetting();

  const [isFontToggle, setIsFontToggle] = useState(false);
  const [productList, setProductList] = useState([]);
  const [bgAmount, setBgAmount] = useState(9);
  const [layoutAmount, setLayoutAmount] = useState(7);
  const [iconAmount, setIconAmount] = useState(6);
  const [typeList, setTypeList] = useState([]);
  const [ copyButtonText, setCopyButtonText ] = useState("Copy link");



  const saveSceneTrigger = () => {
    handleSaveScene();
    setCopyButtonText("Copied!")
    setTimeout(() => {
      setCopyButtonText("Copy link");
    }, 3000)
  }



  useEffect( () => {
    const getBgs = async () => {
      try {
        const bgs = await API.getBgAmount(channel.value, type.value);
        setBgAmount(bgs);
      } catch (error) {
        console.error(error);
      }
    }
    getBgs();
  }, [channel, type])


  const onChangeProduct = value => {
    editProduct(value);
    edits.editProductNames({main: value.label})
    const nextUrl = `${channel.value}/${value.value}`;
    console.log('redirect to ', nextUrl)
    navigate(nextUrl);
  }

  const onChangeChannel = value => {
    editChannel(value);
    edits.reset();
    editBgOption("1");
    setOptionLists(true);

    // set selected item in updated products list
    const prodList = Products[value.value];
    if (prodList) editProduct(prodList[0]);

    // set selected item in updated types list
    const productItem = prodList?.find(item => item.value === prodList[0].value);
    if (productItem?.types && productItem.types.length > 0) {
      editType(productItem.types[0]);
    }

    console.log('redirect to ',value.value )
    navigate(`/${value.value}`)
  }


  const setOptionLists = () => {
    const prodList = Products;
    setProductList(prodList);

    if (isEmptyObj(product) && prodList)  editProduct(prodList[0]);

    const productItem = prodList?.find(item => item.value === product.value);
    if (productItem?.types && productItem.types.length > 0) {
      setTypeList(productItem.types);
      if (!type) editType(productItem.types[0]);
    }
    else setTypeList([]);
  }

  const onChangeType = value => {
    editType(value)
    editBgOption("1");
  }

  useEffect(() => {
    setOptionLists();
  }, [product, channel]);

  return (
    <aside>
      <div className="toolbar-inner">
        <span className="label">Channel</span>
        <Select
          className="select"
          options={options}
          value={channel}
          onChange={value => onChangeChannel(value)}
          size="m"
          placeholder="Select template"
        />

        <span className="label">Product</span>
        <Select
          className="select"
          options={productList}
          value={product}
          onChange={value => onChangeProduct(value)}
          size="m"
          placeholder="Select product"
        />

        <span className="label">Content type</span>
        {typeList.length > 0 && (
          <Select
            className="select"
            options={typeList}
            value={type}
            onChange={value => onChangeType(value)}
            size="m"
            placeholder="Select type"
          />
        )}
        <div className="localization">
          <span className="label">Localization</span>
          <Toggle checked={isFontToggle} className="font-toggle" onChange={e => {
            setIsFontToggle(e.target.checked);
          }}>
          </Toggle>
        </div>

        {isFontToggle && <FontSelector />}




        <span className="label">Layout</span>
        <LayoutSelector amount={layoutAmount}/>


        {layoutOption === 7 && (
          <>
            <span className="label">Icon</span>
            <IconSelector amount={iconAmount}/>
          </>
        )}


        <span className="label">Background</span>
        <BgSelector amount={bgAmount}/>


        <div className="toolbar-btns">
          <Button
            className="btn"
            onClick={saveSceneTrigger}
            size="m"
            mode="outline"
            id={"save_button"}
          >
            <span>{copyButtonText}</span>
          </Button>

          <Button
            className="btn"
            onClick={handleExport}
            size="m"
            id={"export_button"}
            mode="outline"
          >
            <span>Save images</span>
          </Button>
        </div>
      </div>
    </aside>
  );
}

export default Index;