export const URLS = {
  KRAKENUI: `https://resources.jetbrains.com/cai/brand-data/products.json`,
  HUANGHE: 'https://huanghe-api.labs.jb.gg',
  KRAKEN: 'https://kraken.labs.jb.gg',
}

export const API = {
  getPalettes: async () => {
    const palettes = {};
    try {
      const response = await fetch(URLS.KRAKENUI);
      const data = await response.json();
      const krakenProps = data.all;
      for (const product in krakenProps) {
        const colors = krakenProps[product].palette;
        palettes[product] = colors;
      }
      return palettes;
    } catch (error) {
      console.error(error);
    }
  },

  // TODO fix url and request on  changes in selects
  getBgAmount: async (channel, type) => {
      try {
        const response = await fetch(`${URLS.KRAKEN}/tiles/get_tilesets?prefix=Neurobaker/Backgrounds/${channel}/${type}`, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        let result = await response.json();
        return Object.keys(result.tile_sets).length;
      } catch(err) {
        console.error(err); // TypeError: failed to fetch
      }
  },

  sendParams: async obj => {
    try {
      const response = await fetch(`${URLS.KRAKEN}/scene/save?prefix=neurobaker`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(obj)
      });
      let result = await response.json();
      let data = result.scene_id;
      window.location.hash = data;
      console.log('hash ',data);
      return data;
    } catch(err) {
      console.error(err); // TypeError: failed to fetch
    }
  },

  getParams: async () => {
    const hashStr = window.location.hash.slice(1);
    try {
      const response = await fetch(`${URLS.KRAKEN}/scene/load?scene_id=${hashStr}&prefix=neurobaker`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }
      });
      const result = await response.json();
      console.log("result", result)
      return result;
    } catch(err) {
      console.error(err); // TypeError: failed to fetch
    }
  },

  shorten: async value => {
    try {
      const obj = { "text": value }
      const response = await fetch(`${URLS.HUANGHE}/tools/shorten_text`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(obj)
      });
      const result = await response.json();
      return result.shortened_text;
    } catch(err) {
      console.error(err); // TypeError: failed to fetch
    }
  },

  translate: async (value, target_language) => {
    try {
      const obj = {
        "text": value,
        "target_language": target_language
      }
      const response = await fetch(`${URLS.HUANGHE}/tools/translate_text`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(obj)
      });
      const result = await response.json();
      return result.translated_text;
    } catch (err) {
      console.error(err);
    }
  },

}