import React from "react";
import {useSetting} from "../../contexts/setting";
import cn from "classnames";
import './index.css';
import {UI} from "../../data/constants";
import {ICONS} from "../../data/constants";



const Button = ({value, handleClick, src, isCurrent}) => {
  const btnClass = cn('icon-button', { "selected": isCurrent });
  return (
    <button
      value={value}
      className={btnClass}
      onClick={() => handleClick(value)}
      style={{backgroundImage: `url(${src})`}}
    ></button>
  )
}


const IconSelector = ({ amount }) => {
  const {iconOption, editIconOption } = useSetting();
  const getSrc = value =>  (`${UI}/Icons/${ICONS[value]}.svg`);

  console.log('icon option', iconOption)
  console.log(ICONS)
  const onBgButtonClick = value => {
    editIconOption(value)
  }


  return (
    <div className="icon-selector">
      {[...new Array(amount)].map((x, i) => (
        <Button
          key={ICONS[i]}
          value={ICONS[i]}
          src={getSrc(i)}
          handleClick={onBgButtonClick}
          isCurrent={iconOption === ICONS[i]}
        />
      ))}
    </div>
  )}

export default IconSelector;