import React, {useEffect, useState} from "react";
import "./index.css";
import Textarea from "../../../layout/Textarea/Textarea";
import {logoNorm, logoIDE, LABELS, UI} from "../../../data/constants";
import {checkIfIDE} from "../../../libs/tools";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import cn from "classnames";
import Icon from "../../../layout/Icon";
import ImageUpload from "../../../layout/ImageUpload";
const InstSocial = ({ isLocked, id, bgUrl }) => {
  const { product, layoutOption, iconOption } = useSetting();
  const { titles, subtitles, editTitles, editSubtitles } = useEdits();
  const logoURL = checkIfIDE(product.value) ? logoIDE : logoNorm;

  const iconUrl = `${UI}/Icons/${iconOption}.svg`
  const title = titles[id] || titles.main;
  const subtitle = subtitles[id] || subtitles.main;
  const titleClass = cn("title", `layout-${layoutOption}`);
  const subTitleClass = cn("subtitle", `layout-${layoutOption}`);
  const contentClass = cn("content", `layout-${layoutOption}`);
  const wrapperClass = cn("title-wrap", `layout-${layoutOption}`);
  const uploadClass = cn("upload-wrap", `layout-${layoutOption}`);

  return (
    <>
      <span className="label">{`${LABELS[id].label} (${LABELS[id].dimensions})`}</span>

      <div id={id} className="template">
        <div>
          <BgTransformer src={bgUrl} id={id}/>
          <div className={contentClass}>

            <header className="template-header">
              <div className="logo">
                <img src={logoURL} alt="background"/>
              </div>
              {product.value === "JB" ? "" : (
                <div className="product-name">
                  {product.label}
                </div>
              )}
            </header>


            <div className={wrapperClass}>
              <h1 className={titleClass}>
                <Textarea handleTextChange={value => editTitles(value, id, isLocked)} lines="2" text={title}/>
              </h1>
              {[3, 4].includes(layoutOption) && (
                <h2 className={subTitleClass}>
                  <Textarea handleTextChange={value => editSubtitles(value, id, isLocked)} lines="1" text={subtitle}/>
                </h2>
              )}
            </div>


            {layoutOption === 7 && (<div className="icon-wrap">
              <Icon bgUrl={iconUrl}/>
            </div>)}

            {[5, 6].includes(layoutOption) && (<div className={uploadClass}>
              <ImageUpload id={id}/>
            </div>)}
          </div>

        </div>
      </div>
    </>
  );
}

export default InstSocial;