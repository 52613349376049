import { useParams } from "react-router-dom";

import { Products } from '../data/data.js';
import { DEFAULT_PRODUCT } from '../data/constants.js';
import Social from '../pages/Social/index.js';
import {useEffect} from "react";
import {useSetting} from "../contexts/setting";



const TemplateRoute = () => {
  const params = useParams();
  const setting = useSetting();

  useEffect(() => {
    let productOption;
    if (params.product) {
      const { value, label } = Products.find(x => x.value === params.product);
      productOption = { value, label };
    } else {
      productOption = DEFAULT_PRODUCT;
    }
    setting.editProduct(productOption);
    }, []);

    const templates = () => {
        switch(params.template) {
          case "Social":        return <Social />;

          default:      return <h1>Nothing's here</h1>
        }
    }
    return (
        <>
            { templates() }
        </>
    );
}

export default TemplateRoute;