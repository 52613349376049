import React from "react";
import './index.css';


const Icon = ({ bgUrl }) => {
  return (
    <span
      className="icon"
      style={{backgroundImage: `url(${bgUrl})`}}
    ></span>
  )
}


export default Icon;



