

const TrashBin = () => (
  <svg viewBox="0 0 24 24" >
    <path
      fill="#fff"
      d="M18.5 9h-12l1.192 10.767A1.466 1.466 0 0 0 9.2 21h6.6a1.466 1.466 0 0 0 1.508-1.233zm-2.986-6.935l-8.19 2.248A1.8 1.8 0 0 0 6.066 6.53l.29 1.05 11.664-3.2-.293-1.058a1.8 1.8 0 0 0-2.212-1.256z"></path>
  </svg>
)

export default TrashBin;