import Templates from './layout/Templates';
import Toolbar from './layout/Toolbar';
import './App.css';
import './css/fonts.css';
import { useEffect } from "react";
import { ThemeProvider } from '@rescui/ui-contexts';
import { API } from './api.js';
import { Products } from './data/data.js';
import {exportLayouts, isEmptyObj, updateTextareas} from "./libs/tools";
import {options} from "./data/constants";
import {backgroundData} from "./data/backgrounds";
import {useLocation} from "react-router-dom";
import {useEdits} from './contexts/edits.js';
import {DEFAULT_PRODUCT} from "./data/constants";
import {useImages} from "./contexts/images";
import {useSetting} from "./contexts/setting";


const App = () => {
  const { editNames, loadTitles, editProductNames, editDates, editPositions, editFontVariation, dates, names, positions, titles, productNames, fontVariation} = useEdits();
  const { bgX, bgY, bgScale, uploadX, uploadY, uploadScale, editTransform, rerenderBg, customBg, editCustomBg, images, loadImages, editUploadTransform } = useImages();
  const {editProduct, editType, editChannel, editBgOption, editPalettes, bgOption, product, channel, type, layoutOption, editLayoutOption, editIconOption} = useSetting();
  const location = useLocation();
  const theme = 'dark';



  useEffect( () => {
    const fetchParams = async () => {
      if (window.location.hash) {
        const params = await API.getParams();

        if (params) {
          const { product, fontVariation, channel, type, names, titles, dates, positions, productNames, bgScale, bgX, bgY, bgOption, customBg, layoutOption, iconOption, images, uploadY, uploadX, uploadScale  }  = params;


          if (product) editProduct(product);
          if (channel) editChannel(channel);
          if (type) editType(type);
          if (bgOption) editBgOption(+bgOption);
          if (bgOption) editLayoutOption(+layoutOption);
          if (images) loadImages(images);
          if (iconOption) editIconOption(+iconOption);
          if (customBg) editCustomBg(customBg);
          if (!isEmptyObj(names)) editNames(names);
          if (titles) loadTitles(titles);
          if (productNames) editProductNames(productNames);
          if (dates) editDates(dates);
          if (positions) editPositions(positions);
          if (fontVariation) editFontVariation(fontVariation);
          if (bgY || bgX || bgScale) {
            editTransform(bgX, bgY, bgScale);
            rerenderBg();
          }

          if (uploadY || uploadX || uploadScale) {
            editUploadTransform(uploadX, uploadY, uploadScale);
            rerenderBg();
          }
          //TODO fix this when implementing template locks
          updateTextareas(titles.main, 'title');
        }
      }
    }

    fetchParams();
  }, [])

  useEffect( () => {
    const getPalettes = async () => {
      try {
        const krakenPalettes = await API.getPalettes();
        editPalettes(krakenPalettes);
      } catch (error) {
        console.error(error);
      }
    }
    getPalettes();
  }, [])


  useEffect( () => {
    const { x, y, scale } = backgroundData[channel.value][type.value][bgOption];
    editTransform(x, y, scale);
    rerenderBg();
  }, [])

  // useEffect( () => {
  //   rerenderBg();
  // }, [bgScale, bgX, bgY])



  const exportImages = () => {
    const nodes = document.querySelectorAll(".template");
    exportLayouts([...nodes], product.value, channel.value);
  }

  useEffect(() => {
    if (location.pathname === '/') return;
    if (location.hash) return;
    const pathParts = location.pathname.split('/');
    if (pathParts.length < 1) return;

    const newChannelValue = pathParts[1];
    const newChannelOption = options.find(x => x.value === newChannelValue);
    editChannel(newChannelOption);
    const currentChannel = Products[newChannelValue];
    if (!currentChannel) return;

    if (pathParts.length > 2) {
      const newProductValue = pathParts[2];
      const newProductOption = currentChannel?.find(x => x.value === newProductValue);
      editProduct({ label : newProductOption.label, value : newProductOption.value });
    } else {
      const newProductOption = currentChannel[0] || DEFAULT_PRODUCT;
      editProduct({ label : newProductOption.label, value : newProductOption.value });
    }
  }, [location]);


  const saveScene = async () => {
    const scene = {
      product,
      channel,
      type,
      titles,
      dates,
      positions,
      names,
      productNames,
      fontVariation,
      bgX,
      bgY,
      bgScale,
      uploadX,
      uploadY,
      uploadScale,
      bgOption,
      customBg,
      layoutOption,
      images,
    }

    const hash = await API.sendParams(scene);
    const url = `${window.location.origin}${location.pathname}#${hash}`
    navigator.clipboard.writeText(url);
    console.log('saving', hash);
  }


  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <main>
          <Templates />
          <Toolbar
            handleExport={exportImages}
            handleSaveScene={saveScene}
          />
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
